import { gql } from '@apollo/client'

export const LOGIN_EMAIL_CLIENTS_QUERY = gql`
  query loginEmailClients($input: LoginEmailClientsInput!) {
    loginEmailClients(input: $input) {
      id
      name
      assets {
        id
			  filename
			
			  type {
				  id
				  type
		 	  }
      }

      settings {
        id
			  phone 
			  email
        logo_background_color
      }
    }
  }
`