import { gql } from '@apollo/client'

export const ACCOUNT_ACTIVITY_QUERY = gql`
  query AccountActivity($input: AccountActivityInput!) {
    accountActivity(input: $input) {
      id
      active
      last_active
      activation_email_last_sent_at
      activated_at
    }
  }
`
