import React, { useEffect, useState } from 'react'
import { Button } from 'antd'
import { TickerTape } from '@components'
import { cacheBust, getConfig } from '@helpers'

import './SystemMessage.less'

const SystemMessage = () => {
  const [isOnline, setOnline] = useState(true)
  const [isNewVersionPending, setIsNewVersionPending] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)

  useEffect(() => {
    if (isUpdating) {
      cacheBust()
    }
  }, [isUpdating])

  /**
   * Is app offline
   */
  useEffect(() => {
    const setFromEvent = function (event) {
      if (event.type === 'online') {
        setOnline(true)
      } else if (event.type === 'offline') {
        setOnline(false)
      }
    }

    window.addEventListener('online', setFromEvent)
    window.addEventListener('offline', setFromEvent)

    return () => {
      window.removeEventListener('online', setFromEvent)
      window.removeEventListener('offline', setFromEvent)
    }
  })

  /**
   * Check for new versions by comparing
   * .env version to meta.json version stored on s3
   */
  useEffect(() => {
    const config = getConfig();
    
    if (config && config.version > process.env.REACT_APP_VERSION_NO) {
      setIsNewVersionPending(true);
    }

    const intervalId = setInterval(() => {
      const config = getConfig()

      if (config && config.version > process.env.REACT_APP_VERSION_NO) {
        setIsNewVersionPending(true)
      }
    }, 1000 * 60 * 1)

    return () => clearInterval(intervalId); // Cleanup
  }, [])

  const handleReload = () => {
    setIsUpdating(true)
  }

  if (!isOnline) {
    return (
      <TickerTape className='sb-system-message' title='You are currently offline'>
        Access to the application will be limited.
      </TickerTape>
    )
  }

  if (isNewVersionPending) {
    return (
      <TickerTape className='sb-system-message' title='New Version Available'>
        <div className='sb-system-message__old-version-content'>
          Click the update button to use the latest version.
          <Button
            className='sb-system-message__old-version-button'
            loading={isUpdating}
            shape='round'
            size='large'
            ghost
            onClick={handleReload}
          >
            Update Version
          </Button>
        </div>
      </TickerTape>
    )
  }

  return null
}

export default SystemMessage
