import { gql } from '@apollo/client'
import { NOTIFICATION_FIELDS_FRAGMENT } from '../fragments'

const AUTH_USER_QUERY = gql`
  ${NOTIFICATION_FIELDS_FRAGMENT}

  query {
    me {
      id
      email
      defaultEvent {
        id
      }
      client {
        id
        name
        assets {
          id
          filename
        
          type {
            id
            type
          }
        }

        settings {
          id
          phone 
          email
          logo_background_color
        }
      }
      unreadNotificationCount
      myNotifications(limit: 100) {
        ...NotificationFields
      }
    }
  }
`

const ME_QUERY = gql`
  query {
    me {
      id
      last_active
      email
      mobile
      name
      client {
        id
        name
        assets {
          id
          filename
        
          type {
            id
            type
          }
        }

        settings {
          id
          phone 
          email
          logo_background_color
        }
      }
    }
  }
`

export { AUTH_USER_QUERY, ME_QUERY }
