import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

const toDayJs = (date = null) => {
  if (typeof date === 'string') {
    return dayjs.utc(date, 'YYYY-MM-DD-H:nn:ss').local()
  }

  if (date === null) {
    return dayjs()
  }

  return date
}

export default toDayJs