import React from 'react'
import { SchoolContactDetails } from '@components'
import { Typography } from 'antd'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import parentsLogo from '@assets/parents-logo.svg'

import './Sidebar.less'

const { Title } = Typography

const Sidebar = ({ 
  children, 
  client, 
  mode, 
  title, 
  showContactDetails, 
  showSchoolBookingTitle 
}) => (
  <div className={classnames({ sidebar: true, [`${mode}-background`]: mode })}>
    <img src={parentsLogo} alt='Parents logo' className='logo' />

    <div className='header'>
      {showSchoolBookingTitle && (
        <Title level={2} className='title'>
          SchoolBooking
        </Title>
      )}

      <Title level={showSchoolBookingTitle ? 3 : 2} className='subtitle'>
        {title}
      </Title>

      {showContactDetails &&
        <SchoolContactDetails phone={client?.settings?.phone} email={client?.settings?.email} />  
      }
    </div>

    {children}
  </div>
)

Sidebar.props = {
  client: PropTypes.object,
  mode: PropTypes.string,
  showContactDetails: PropTypes.bool,
  title: PropTypes.string.isRequired
}

Sidebar.defaultProps = {
  client: null,
  mode: '',
  showContactDetails: false,
  showSchoolBookingTitle: true
}

export default Sidebar
