import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import App from './App'
import { BrowserRouter as Router } from 'react-router-dom'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import { getClient } from '@services'
import { ApolloProvider } from '@apollo/client'
import './index.css'

Sentry.init({
  dsn: 'https://e83743c68f874049ae2afe41857ff691@o1146767.ingest.sentry.io/6216203',
  environment: process.env.REACT_APP_ENV,
  release: process.env.REACT_APP_VERSION_NO
})

getClient().then(client => {
  ReactDOM.render(
    <React.StrictMode>
      <Router>
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </Router>
    </React.StrictMode>,
    document.getElementById('root')
  )

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://cra.link/PWA
  serviceWorkerRegistration.register({
    onUpdate: registration => {
      if (registration.waiting) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      }
    }
  })

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals()
})
