import { gql } from '@apollo/client'

export const CLIENT_PUBLIC_QUERY = gql`
  query ($id: ID!) {
    clientPublic(id: $id) {
      id
      name
      assets {
        id
			  filename
			
			  type {
				  id
				  type
		 	  }
      }

      settings {
        id
			  phone 
			  email
        logo_background_color
      }
    }
  }
`
