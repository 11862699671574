import React, { useContext } from 'react'
import { Container, UserMenu } from '@components'
import { UserContext } from '@context'
import { getClientLogo } from '@helpers'
import { Button, Skeleton, Typography } from 'antd'
import schoolBookingLogo from '@assets/school-booking-logo.svg'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import './Hero.less'

const Hero = ({ loading, showNavigation, showUserMenu, title, description }) => {
  const { user } = useContext(UserContext)
  const client = user?.client
  const clientLogo = getClientLogo(client) || null

  return (
    <section className='sb-hero'>
      <Container>
        {loading && <Skeleton paragraph={{ rows: 2 }} active />}

        {!loading && (
          <div className='sb-hero__wrapper'>
            <div className='sb-hero__actions'>
              <div className='sb-hero__navigation'>
                {showNavigation && (
                  <div className='sb-hero__buttons'>
                    <Button type='primary' shape='round' size='large'>
                      <Link to={`/parentsmeeting`}>View Your Events</Link>
                    </Button>
                  </div>
                )}
              </div>
              
              {showUserMenu && (
                <UserMenu />
              )}
            </div>

            <div className='sb-hero__top-bar'>
              { clientLogo ? (
                <div className="sb-hero__logo-container" style={{ backgroundColor: client.settings.logo_background_color }}>
                  <img className="sb-hero__logo" src={clientLogo} alt={client?.name || 'Parent Login'} />
                </div>
              ) : <img className="sb-hero__sb-logo" src={schoolBookingLogo} alt={client?.name || 'Parent Login'} />}
            
              <div className='sb-hero__content'>
                <Typography.Title level={2} className='sb-hero__title'>{title}</Typography.Title>

                <Typography.Paragraph className='sb-hero__paragraph'>{description}</Typography.Paragraph>
              </div>
            </div>
          </div>
        )}
      </Container>
    </section>
  )
}

Hero.propTypes = {
  description: PropTypes.string,
  loading: PropTypes.bool,
  showLogo: PropTypes.bool,
  showUserMenu: PropTypes.bool,
  showNavigation: PropTypes.bool,
  subheader: PropTypes.string,
  title: PropTypes.string
}

Hero.defaultProps = {
  description: '',
  loading: false,
  showLogo: false,
  showNavigation: true,
  showUserMenu: true,
  subheader: '',
  title: ''
}

export default Hero
