import React, { useState } from 'react';
import { ErrorMessage } from '@components';
import { SEND_ACTIVATION_EMAIL_MUTATION } from '@graphql';
import { useMutation } from '@apollo/client';
import { Button, Space, Typography } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types';

import './AccountActivationRequired.less';

const { Text, Title } = Typography;

const AccountActivationRequired = ({ 
  client, 
  email, 
  handleBack = () => {}, 
  message, 
  showBackButton = true 
}) => {
  const [error, setError] = useState(null);
  const [isActivationEmailSent, setIsActivationEmailSent] = useState(false);

  const [sendActivationEmail, { loading }] = useMutation(SEND_ACTIVATION_EMAIL_MUTATION, {
    variables: {
      input: {
        email: email,
        client_id: client?.id
      }
    },
    
    onCompleted: (data) => {
      if (data.sendActivationEmail) {
        setIsActivationEmailSent(true)
      }      
    },

    onError: (error) => setError(error)
  })

  const handlePrevious = () => {
    setIsActivationEmailSent(false)
    handleBack()
  }

  if (isActivationEmailSent) {
    return (
      <div id="activation-email-sent">
        <div className="success">
          <div className="success-message">
            <CheckCircleOutlined style={{ fontSize: 50, color: 'white' }} />
            <Text>Account activation instructions sent to:</Text>
            <Title className='email' level={5}>{email}</Title>
          </div>

          <Space className='resend-email'>
            <Text className='intro'>
              If your email doesn't arrive in the next 5 minutes check your spam folder.
            </Text>
          </Space>

          {showBackButton && (
            <Button type="link" block size="large" shape="round" onClick={() => handlePrevious()}>
              Previous
            </Button>
          )}
        </div>
      </div>
    )
  }
  
  if (error) {
    return (
      <div id="activation-email">
        <ErrorMessage message={error.message} />

        <Button type="primary" block shape="round" size="large" loading={loading} onClick={() => sendActivationEmail()}>Resend Activation Email</Button>

        { showBackButton && (
          <Button type="link" block size="large" shape="round" onClick={() => handleBack()}>
            Previous
          </Button>
        )}
      </div>
    )
  }


  return (
    <div id="activation-email">
      <Text className="intro" type="danger" strong>{message}</Text>

      <Button type="primary" block shape="round" size="large" loading={loading} onClick={() => sendActivationEmail()}>Resend Activation Email</Button>

      { showBackButton && (
        <Button type="link" block size="large" shape="round" onClick={() => handleBack()}>
          Previous
        </Button>
      )}
    </div>
  )
}

AccountActivationRequired.propTypes = {
  client: PropTypes.object,
  email: PropTypes.string.isRequired,
  handleBack: PropTypes.func,
  message: PropTypes.string.isRequired,
  showBackButton: PropTypes.bool
}

export default AccountActivationRequired